

.gallery-container {
    display: flex;
    flex-basis: 15%;
    flex-wrap: wrap;
    margin: 0 3%;
}

.gallery-body {
    background-color: white;
    padding-bottom: 2vh;
}

.gallery-body h1 {
    margin-left: 6%;
}

.gallery-card {
    cursor: pointer;
    max-height: 150px;
    overflow: hidden;
    height: fit-content;
}

.gallery-card:hover {
    box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)
}

@media screen and (max-width: 641px) {
    .gallery-body h1 {
        margin-left: 6%;
        font-size: 1.2rem !important;
        padding-top: 1%;
    }
    .gallery-card {
        width: 31%;
        margin: 1% 1%;
    }

    svg.arrow {
        font-size: 0;
    }
}

@media screen and (min-width: 642px) {
    .gallery-body h1 {
        margin-left: 6%;
        padding-top: 3%;
    }
    .gallery-card {
        width: 18%;
        margin: 1% 1%;
    }

    svg.arrow {
        font-size: 4rem;
    }
}

.show {
    display: block !important;
}

.dontShow {
    display: none !important;
}

.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    padding: 5%;
}

.modal img {
    height: auto;
    width: auto;
    max-height: 90vh;
    max-width: 85vw;
    margin-top: 4%;
}

.close-gallery-modal {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 5%;
}

.showMoreContainer {
    align-items: center;
    display: flex;
    height: 5vh;
    width: 100%;
    justify-content: center;
    margin: 1vh;
}

.showMoreContainer button {
    height: 100%;
    border: none;
    font-size: x-large;
    padding: 0 6vw;
    border-radius: 5px;
}

.showMoreContainer button:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.app-content {
    width: 100%;
    position: relative;
    max-width: 1420px;
    margin: 0 auto;
}



.fileInput {
    border-bottom: 4px solid lightgray;
    border-right: 4px solid lightgray;
    border-top: 1px solid black;
    border-left: 1px solid black;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
}

.loadingOverlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.65);
}

.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 200px;
    width: 500px;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 5px solid gray;
    border-bottom: 5px solid gray;
}

.previewText {
    width: 100%;
    margin-top: 20px;
}

.submitButton {
    padding: 12px;
    margin-left: 10px;
    background: white;
    border: 4px solid lightgray;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
}

.centerText {
    text-align: center;
    width: 500px;
}

@font-face {
    font-family: "Industry Black Italic";
    src: url("../fonts/industry-blackitalic.woff") format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Industry Black";
    src: url("../fonts/industry-black.woff") format('woff');
    font-weight: 900;
}

@font-face {
    font-family: "Industry Bold";
    src: url("../fonts/industry-bold.woff") format('woff');
    font-weight: bold;
}



@font-face {
    font-family: "Industry Bold Italic";
    src: url("../fonts/industry-bolditalic.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Industry Demi";
    src: url("../fonts/industry-demi.woff") format('woff');
    font-weight: 600;
}

@font-face {
    font-family: "Industry Demi Italic";
    src: url("../fonts/industry-demiitalic.woff") format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Industry Medium";
    src: url("../fonts/industry-medium.woff") format('woff');
    font-weight: 400;
}

@font-face {
    font-family: "Industry Medium Italic";
    src: url("../fonts/industry-mediumitalic.woff") format('woff');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: "Industry Book";
    src: url("../fonts/industry-book.woff") format('woff');
    font-weight: 300;
}


h4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

h5 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.tab-section {
    width: 100%;
    overflow: auto;
}

.tab-heading-section {
    display: flex;
    top: 36px;
    position: relative;
}

@media screen and (max-width: 699px) {
    .carArrow {
        display: none;
    }
}

@media screen and (min-width: 700px) {
    .carArrow {
        font-size: 4rem;
        background: rgba(0, 0, 0, 0) !important;
    }
}

.flex-row {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.trip-info-section {
    margin-top: 10vh;
    position: center;
    width: 100%;
    min-height: 80vh;
    overflow: visible;
    color: black !important;
}

@media screen and (min-width: 351px) {
    .trip-info-section {
        margin-top: 3vh;
    }
    .tab-content-section {
        padding: 7vh 2vw;
    }
}

@media screen and (min-width: 800px) {
    .trip-info-section {
        margin-top: 6vh;
    }
    .tab-content-section {
        padding: 7vh 0;
    }
}

.tab-content-section {
    display: flex;
    background-color: white;
    color: black;
    height: 100%;
    border: 1px solid #4a7740;
    min-height: 50vh;
}

.tab-image-section {

    height: 70vh;
    margin: 5px;
}

.tab-text-section-large {
    width: 30vw;
    height: 70vh;
    margin: 8px;
}

.tab-image-section, .tab-it-image-section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.tab-text-section {
    font-size: 1.1rem;
    overflow: auto;
}

.tab-heading-offset {
    flex: 1;
}

.background-color-non-selected {
    background-color: #4a7740;
}

.background-color-selected {
    background-color: white;
}

.first-tab {
    margin-left: 10%
}

.overview-tab-banner {
    margin: 1% 3% -1% 10%;
    flex: 3;
    color: white;
}

.tab-heading {
    flex: 2;
    padding: 0 10px;
    text-align: center;
    background-color: #E9E9E9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #4a7740;
    border-top: 1px solid #4a7740;
    border-bottom: 1px solid #4a7740;
    text-transform: uppercase;
    overflow: auto;
}

.last-tab {
    border-right: 1px solid #4a7740;
}

.tab-heading:hover {
    background-color: white !important;
}
.tab-heading:hover  {
    background-color: white;
    border-bottom: none;
    color: black;
}

.tab-heading:hover > h5 {
    color: var(--med-green);
    border-bottom: 2px solid var(--med-green);
    transition: width 0.4s;
}

.image-caption {
    position: absolute;
    font-weight: bold;
    letter-spacing: .025em;
    line-height: 1.25em;
}

@media screen and (max-width: 600px) {
    .image-caption {
        font-size: 1.0rem;
        width: 42vw;
        bottom: 2vh;
        left: 2vw;
    }
    h4 {
        font-size: 1.2rem !important;
    }
    h5 {
        font-size: 1rem !important;
    }
    .tab-text-section {
        font-size: 0.9rem;
    }
}

@media screen and (min-width: 601px) {
    .image-caption {
        font-size: 1.2rem;
        width: 35vw;
        bottom: 7vh;
        left: 3vw;
    }
    h4 {
        font-size: 1.5rem !important;
    }
    h5 {
        font-size: 1.25rem !important;
    }
    .tab-text-section {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1100px) {
    .image-caption {
        font-size: 1.6rem;
        width: 30vw;
        bottom: 6vh;
        left: 3vw;
    }

    .tab-text-section {
        font-size: 1.1rem;
    }
}

.col-md-3 {
    padding: 0;
}

.margin-small-container {
    margin: 20px;
}

.padding-heading {
    padding: 20px;
}




.form-card > div {
    padding: 20px;
}

@media screen and (max-width: 600px) {
    .form-card {
        width: 90%;
        margin: 2% auto;
    }
}

@media screen and (min-width: 601px) {
    .form-card {
        width: 50%;
        margin: 8% auto;
    }
}

@media screen and (min-width: 1100px) {
    .form-card {
        width: 40%;
        margin: 10% auto;
    }
}

.MuiOutlinedInput-root {
    margin-bottom: 3%;
}

.MuiTextField-root {
    margin-bottom: 3%;
}

.contact-banner {
    margin-bottom: 7%;
}

.contact-submit-button {
    background-color: #4a7740;
    color: white;
    text-transform: uppercase;
    padding: 10px 15px;
    cursor: pointer;
}

.contact-submit-button:hover {
    background-color: #0b3442;
}

.main-body {
    color: black;
    margin: 0 1vw 0 1vw;
}

.stats {
    font-size: 0.9em;
    margin-top: 2vh;
    color: black;
}

.flex-column-it {
    display: flex;
    flex-direction: column;
}

.tab-it-image-section {
    width: 45vw;
    margin: 5px;
}

.tab-text-it-section-large {
    width: 48vw;
    margin: 5px;
}

.stat-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    height: 8em;
}

.center {
    align-items: center;
    justify-content: center;
}

.center-text {
    text-align: center;
}

.skill-hover-modal {
    position: fixed;
    background-color: black;
    color: white;
    width: 450px;
    top: 0;
    left: 0;
    z-index: 1050;
    outline: 0;
    opacity: 0;
    display: none;
    text-align: left;
}

.stat-text {
    color: #0b3442;
    font-weight: bold;
    font-style: italic;
    font-size: 0.85em;
    cursor: pointer;
}

.more-info {
    color: lightskyblue;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
    padding: 20px;
}

.stat-level {
    font-weight: bold;
    font-size: 1.12em;
    font-style: normal;
    color: black;
}

.skill-level-modal {
    padding: 10px;
}

.skill-level-modal h5 {
    text-align: center;
}

.skills-fitness-modal {
    margin: 0 auto;
    margin-top: 6%;
    background: white;
    padding: 2%;
    text-align: left;
}

@media screen and (min-width: 642px) {
    .skills-fitness-modal {
        max-width: 80%;
    }
    .close-skills-modal {
        right: 13%;
    }
}

@media screen and (max-width: 641px) {
    .skills-fitness-modal {
        max-width: 95%;
    }
    .close-skills-modal {
        right: 7%;
    }
}

.close-skills-modal {
    background: none;
    border: none;
    font-size: 1.5rem;
    height: 40px;
    width: 40px;
    position: absolute;
}

.skills-modal {
    overflow: scroll !important;
}
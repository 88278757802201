body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black !important;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: "Industry Medium", Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: .015rem;
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
    --med-green: #4a7740;
    --olive-green: #30472c;
    --dark-green: #26291e;
    --dark-teal: #0b3442;
    --lt-gray: #b7b7b7;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.col {
    flex: 1;
    margin: 0;
}

img {
    width: 100%;
    margin: 0;
}

figure {
    margin: 0;
}

/***********************
      Hero Section
***********************/
.hero-section {
    position: relative;
    background-color: #0b3442;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    width: 100%;
    overflow: hidden;
    color: white;
}

@media screen and (max-width: 350px) {
    .hero-section {
        height: 25vh;
    }
}

@media screen and (min-width: 351px) {
    .hero-section {
        height: 35vh;
    }
}

@media screen and (min-width: 600px) {
    .hero-section {
        height: 45vh;
    }
}

@media screen and (min-width: 800px) {
    .hero-section {
        height: 55vh;
    }
}

@media screen and (min-width: 1100px) {
    .hero-section {
        height: 65vh;
    }
}

@media screen and (min-width: 1200px) {
    .hero-section {
        height: 75vh;
    }
}

@media screen and (min-width: 1500px) {
    .hero-section {
        height: 75vh;
    }
}

.row {
    padding: 1rem;
}

.hero-caption {
    position: absolute;
    bottom: 1em;
    left: 1em;
    color: white;
    font-weight: bold;
    font-size: 2.5em;
    width: 60%;
}


.tab-dates-section {
    width: 100%;
}

.pricing-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.pricing-row p {
    flex: 1;
    text-align: center;
    margin: 0 2px;
    white-space: pre-wrap;
}

.required-text {
    margin-bottom: 5%;
    font-size: 0.8rem;
}

.asterisk {
    color: red;
}

@media screen and (max-width: 600px) {
    .pricing-row {
        margin-bottom: 1%;
    }
}

@media screen and (min-width: 601px) {
    .pricing-row {
        margin-bottom: 4%;
    }
    .pricing-row > p:first-child {
        font-weight: bold;
    }
    .date-one-button {
        margin-left: 10px;
    }
}

.date-one-button {
    text-decoration: underline;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #4a7740;
    color: white;
}

label {
    color: black !important;
}

.available-date {
    font-weight: bold;
    color: #4a7740;
    border: 1px solid #4a7740;
    text-transform: uppercase;
    padding: 8px 12px;
    height: fit-content;
}

.title-row {
    align-items: flex-end;
    font-weight: bold;
}

.book-trip-banner-container {
    display: flex;
    justify-content: space-between;
}

.book-trip-banner {
    margin-bottom: 4%;
}

.tab-dates-section h5 {
    font-weight: bold !important;
}

.showSignUp {
    display: block !important;
}

.dontShowSignUp {
    display: none !important;
}

.column-1 {
    flex: 1;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
}

label {
    margin-top: 2% !important;
}

.modal {
    padding: 0 !important;
    text-align: center;
}

button.MuiButtonBase-root {
    color: black !important;
}

.column-2 {
    flex: 1;
    margin-left: 1%;
}

.sign-up-form-card > div {
    padding: 20px;
}

.sign-up-form-card .close-sign-up {
    background: none;
    border: none;
    color: black;
    font-size: 1.5rem;
    height: 40px;
    width: 40px;
}

button.close-sign-up {
    position: center !important;
    right: 0 !important;
}
#signUpModal {
    overflow: scroll;
}

.flexible-error {
    bottom: 15px;
    position: relative;
    color: red;
}

.sign-up-form-card {
    text-align: initial;
}

@media screen and (max-width: 600px) {
    .sign-up-form-card {
        width: 90%;
        margin: 2% auto;
    }
}

@media screen and (min-width: 601px) {
    .sign-up-form-card {
        width: 100%;
        margin: 5% auto;
        max-width: 800px;
    }
}

@media screen and (min-width: 1100px) {
    .sign-up-form-card {
        width: 75%;
        margin: 4% auto;
    }
}

.book-submit-button {
    background-color: #4a7740;
    color: white;
    text-transform: uppercase;
    padding: 10px 15px;
    cursor: pointer;
}

.disabled-submit {
    background-color: #95999c;
    cursor: not-allowed;
}

.success-modal {
    align-items: center;
}

.success-modal img {
    max-width: 600px;
    margin: 0 auto;
}

.success-modal .close-sign-up {
    align-self: flex-end;
}

.PrivateNotchedOutline {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
}

.error-email {
    color: red;
}

.errorBorder {
    border-color: #f44336 !important;
}

.inner-tab-content-section-w {
    display: flex;
    background-color: white;
    min-height: 60vh;
    color: black;
    flex-direction: column;
    border: 1px solid #4a7740;
}

.inner-tab-content-section-bikes {
    display: flex;
    background-color: white;
    min-height: 60vh;
    color: black;
    flex-direction: column;
    border: 1px solid #4a7740;
}

@media screen and (max-width: 600px) {
    .inner-tab-content-section-w {
        padding: 12% 0;
    }
}

@media screen and (min-width: 600px) {
    .inner-tab-content-section-w {
        padding: 7% 2%;
    }
}

.tab-section-w {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    overflow: auto;
}

.wi-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
}

.tab-heading-w {
    flex: 2;
    padding: 0 10px;
    text-align: center;
    background-color: ghostwhite;
    border-left: 1px solid #4a7740;
    border-top: 1px solid #4a7740;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: relative;
}

.tab-heading-w:hover  {
    background-color: white;
    border-bottom: none;
    color: black;
}

.tab-heading:hover:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid white;
}

.gear-repair-personal {
    margin-left: 9%;
}

.tab-heading-w:hover > h5 {
    color: var(--med-green);
    border-bottom: 2px solid var(--med-green);
    transition: width 0.4s;
}

h6 {
    font-weight: bold !important;
}

.activeHeading h5 {
    border-bottom: 2px solid var(--med-green);
}

.tab-content-section-w {
    display: flex;
    height: 100%;
    border: 1px solid #E9E9E9;
}

.activeHeading {
    position: relative;
    background-color: white;
    border-bottom: 2px solid white;
    color: #0b3442;
}

.activeHeading:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid white;
}

.banner {
    flex: 1;
    padding-top: 4%;
}

.banner-right {
    flex: 2;
}

.tab-banner-w {
    min-height: 20vh;
    display: flex;
    padding: 2% 10%;
}

@media screen and (max-width: 600px) {
    .tab-banner-w {
        padding-top: 10%;
    }
}

@media screen and (min-width: 601px) {
    .tab-banner-w {
        padding-top: 2%;
    }
}

.bike-section {
    flex: 1;
}

.bike-section p {
    margin: 4% 4%;

}

.right-section {
    padding: 0 2%;
}

@media screen and (max-width: 600px) {
    .whats-included-section {
        padding: 0 8% 0 4%;
    }
}

@media screen and (min-width: 601px) {
    .whats-included-section {
        padding: 0 10%;
    }
}

.flex-1 {
    flex: 1;
}
